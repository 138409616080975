<template>
    <div
        class="toggler horizontal-centered-content p-2"
        :class="`toggler-${color}`"
    >
        <label :class="{ active: !isChecked }">{{ left }}</label>
        <label class="switch">
            <input type="checkbox" v-model="isChecked" :disabled="disabled" />
            <span class="slider round" :class="`slider-${color}`"></span>
        </label>
        <label :class="{ active: isChecked }">{{ right }}</label>
    </div>
</template>

<script>
export default {
    name: "custom-switch",
    props: {
        checked: {
            type: Boolean,
        },
        left: {
            type: String,
            required: true,
        },
        right: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            default: "green",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["update:checked", "changed"],
    data() {
        return {
            isChecked: false,
        };
    },
    mounted() {
        this.isChecked = this.checked;
    },
    watch: {
        checked() {
            this.isChecked = this.checked;
        },
        isChecked(checked) {
            this.$emit("update:checked", checked);
            this.$emit("changed");
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";

.toggler {
    justify-content: center;

    label {
        padding: 0rem 0.5rem;
        color: gray;

        &.active {
            font-weight: bold;
        }
    }

    &.toggler-green {
        label {
            &.active {
                color: $primary;
            }
        }
    }

    &.toggler-red {
        label {
            &.active {
                color: $error;
            }
        }
    }
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &.slider-green {
        background-color: $primary;
    }

    &.slider-red {
        background-color: $error;
    }
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .slider-green {
    background-color: $primary;
}

input:checked + .slider-red {
    background-color: $error;
}

input:focus + .slider-red {
    box-shadow: 0 0 1px $primary;
}

input:focus + .slider-green {
    box-shadow: 0 0 1px $error;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
</style>